import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

// components
import Layout from "../components/layout/Layout";
import Loader from "../components/loader/Loader";
import Carousel from "../components/glide/Glide";
import Seo from "../components/seo/SEO";

const IndexPage = () => {
  const [loaded, setLoaded] = useState(false)

  let data = useStaticQuery(
    graphql`{
      craft {
        entry(section: "landingPage") {
          ... on Craft_landingPage_landingPage_Entry {
            pageSeo {
              title
              description
              keywords {
                keyword
              }
              advanced {
                  robots
                  canonical
              }
              social {
                  twitter {
                      image {
                          ... on Craft_images_Asset {
                              url
                          }
                      }
                  }
                  facebook {
                      image {
                          ... on Craft_images_Asset {
                              url
                          }
                      }
                  }
              }
            }
          }
        }
        globalSet(handle: "featuredContent") {
          ... on Craft_featuredContent_GlobalSet {
            entriesList {
              typeHandle
              uri
              title
              ... on Craft_films_films_Entry {
                contentMatrix {
                  ... on Craft_contentMatrix_contentBlock_BlockType {
                    typeHandle
                    featuredImage {
                      ... on Craft_films_Asset {
                        title
                        url
                        imageFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                        }
                      }
                    }
                    mobileImage {
                      ... on Craft_films_Asset {
                        title
                        url
                        imageFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Craft_series_series_Entry {
                contentMatrix {
                  ... on Craft_contentMatrix_contentBlock_BlockType {
                    typeHandle
                    featuredImage {
                      ... on Craft_series_Asset {
                        title
                        url
                        imageFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                        }
                      }
                    }
                    mobileImage {
                      ... on Craft_films_Asset {
                        title
                        url
                        imageFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Craft_books_books_Entry {
                bookMatrix {
                  ... on Craft_bookMatrix_contentBlock_BlockType {
                    typeHandle
                    featuredImage {
                      ... on Craft_books_Asset {
                        title
                        url
                        imageFile {
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`
  );

  const seo = data.craft.entry.pageSeo;

  let keywords = [];
  if (seo.keywords) {
    seo.keywords.forEach((keyword) => {
      keywords.push(keyword.keyword);
    })
  }

  const featuredEntries = data.craft.globalSet.entriesList;

  const width = typeof window === `undefined` ? 1280 : window.innerWidth;
  const mobile = width < 1000;
  const carouselOptions = { 
    type: 'carousel', 
    perView: 1, 
    startAt: 0, 
    peek: mobile ? 0 : 200, 
    gap: 0, 
    autoplay: 5000,
    animationDuration: 1100,
  };

  // wait for images to load to show page
  useEffect(() => {
    const loadImage = image => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image()
        loadImg.src = image.url
        loadImg.onload = () => resolve(image.url)
        loadImg.onerror = err => reject(err)
      })
    }

    Promise.all(featuredEntries.map(item => {
        let image = null;
        if (item.typeHandle === 'films' || 'series') {
          image = item.contentMatrix ? item.contentMatrix[0].featuredImage[0] : null;
        } else {
          image = item.bookMatrix ? item.bookMatrix[0].featuredImage[0] : null;
        }
        return loadImage(image);
      }))
      .then(() => setLoaded(true))
      .catch(err => console.log("Failed to load images", err))
  }, [featuredEntries])

  return (
    <Layout>
      <Seo title={'Home'} 
            canonical={seo.advanced.canonical}
            description={seo.description ? seo.description : null} 
            facebookImage={seo.social.facebook.image?.url}
            robots={seo.advanced.robots}
            twitterImage={seo.social.twitter.image?.url}
            keywords={keywords} />
      { loaded ? (
        <Carousel enabled options={carouselOptions} bullets arrows>
          {featuredEntries.map((item, i) => {
            let image = null;
            if (item.typeHandle === 'films' || 'series') {
              image = item.contentMatrix ? item.contentMatrix[0].featuredImage[0] : null;
            } else {
              image = item.bookMatrix ? item.bookMatrix[0].featuredImage[0] : null;
            }

            let mobileImage = null;
            if (item.typeHandle === 'films' || 'series') {
              mobileImage = item.contentMatrix && item.contentMatrix[0].mobileImage ? item.contentMatrix[0].mobileImage[0] : null;
            }

            return (
              <Link to={item.uri} key={i}>
                <div style={{ overflow: 'hidden' }} className={`glide__image gatsby-image-wrapper flex full align-center justify-center ${mobileImage ? 'no-medium' : ''}`}>
                  {image ? <img style={{ position: 'relative' }} src={image.url} alt={image.title} /> : null}
                </div>

                {mobileImage ? (
                  <div style={{ overflow: 'hidden', height: '100%' }} className={`glide__image gatsby-image-wrapper flex full align-center justify-center no-desktop`}>
                    <img style={{ position: 'relative' }} src={mobileImage.url} alt={mobileImage.title} />
                  </div>
                  ) : null
                }
                
                <div className={`flex align-center justify-between`}>
                  <h1>{item.title}</h1>
                  <h2 className={`capitalize`}>{item.typeHandle}</h2>
                </div>
              </Link>
            )
          })}
        </Carousel>)
        : (
          <div className="loader-wrapper" style={{ minHeight: '100vh' }}>
            <Loader />
          </div>
        )}
    </Layout>
  )
}

export default IndexPage