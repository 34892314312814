import React from 'react';
import Glide from "@glidejs/glide";

// components
import LeftArrow from '../../svgs/left_arrow.svg';
import RightArrow from '../../svgs/right_arrow.svg';

// styles
import "../glide/glide.scss";

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null
    }
  }
  
  componentDidMount() {
    this.setState(
      { id: `glide-${Math.ceil(Math.random() * 100)}` },
      this.initializeGlider
    );
  }

  initializeGlider = () => {
    this.slider = new Glide(`#${this.state.id}`, this.props.options);

    this.props.enabled ? this.slider.mount() : this.slider.disable();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.options.startAt !== newProps.options.startAt) {
      this.slider.go(`=${newProps.options.startAt}`);
    }
  }
  
  render() {
    const bullets = <div className="glide__bullets flex align-center justify-center" data-glide-el="controls[nav]">
      {this.props.children.map((slide, index) => {
        return (
          <button
            key={index}
            className="glide__bullet"
            data-glide-dir={"=" + index}
            title={index}
            aria-label="slide button"
          />
        );
      })}
    </div>
    
    return (
      <div className={`glide`} id={this.state.id}>
        <div className={`glide__track`} data-glide-el="track">
          <div className={`flex align-center justify-center`}>
            {this.props.children.map((slide, index) => {
              return React.cloneElement(slide, {
                key: index,
                className: `glide__child`
              });
            })}
          </div>
        </div>

        <div className={`glide__bottom-container flex justify-center`} data-glide-el="controls">
          <button className="glide__arrow glide__arrow--left" data-glide-dir="<" title="start">
            <LeftArrow />
          </button>
          {this.props.bullets ? bullets : null}
          <button className="glide__arrow glide__arrow--right" data-glide-dir=">" title="end">
            <RightArrow />
          </button>
        </div>
        
      </div>
    )
  }
}